.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-content {
    width: 80%;
    max-width: 600px;
    min-width: 100px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.options .option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.option input[type="checkbox"] {
    margin-right: 10px;
}

.close-button, .close-button-all {
    display: block;
    background-color: #2e3f4f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
}

.close-button-all{
    background-color: #63438e;
}

.close-button-all:hover{
    background-color: rgba(99, 67, 142, 0.9);
}

.close-button:hover {
    background-color: #333;
}

.no-scroll {
    overflow: hidden;
    height: 100%;
}

.option1>label, .option2>label, .option3>label{
    margin-left: 10px;
}















/*10*/
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/*42*/
*, *::before, *::after {
    box-sizing: border-box;
}
/*45*/
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/*51*/
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

/*54*/
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
/*72*/
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
/*76*/
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
/*123*/
sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
/*132*/
sup {
    top: -.5em;
}
/*135*/
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #0056b3;
    text-decoration: underline; }
a:not([href]) {
    color: inherit;
    text-decoration: none; }
a:not([href]):hover {
    color: inherit;
    text-decoration: none; }
pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto; }

figure {
    margin: 0 0 1rem; }
/*165*/
img {
    vertical-align: middle;
    border-style: none;
}
/*169*/
svg {
    overflow: hidden;
    vertical-align: middle;
}
/*190*/
button {
    border-radius: 0;
}
/*201*/
input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
/*208*/
button, input {
    overflow: visible;
}
/*212*/
button, select {
    text-transform: none;
}
/*221*/
button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}
/*227*/
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
}
/*301*/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
/*309*/
h2, .h2 {
    font-size: 2rem;
}
/*392*/
.img-fluid {
    max-width: 100%;
    height: auto;
}
/*446*/
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
/*453*/
@media (min-width: 576px){
    .container {
        max-width: 540px;
    }
}
/*456*/
@media (min-width: 768px){
    .container {
        max-width: 720px;
    }
}
/*459*/
@media (min-width: 992px){
    .container {
        max-width: 960px;
    }
}
/*462*/
@media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
}
/*465*/
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
/*473*/
@media (min-width: 576px){
    .container, .container-sm {
        max-width: 540px;
    }
}
/*477*/
@media (min-width: 768px){
    .container, .container-sm, .container-md {
        max-width: 720px;
    }
}
/*481*/
@media (min-width: 992px){
    .container, .container-sm, .container-md, .container-lg {
        max-width: 960px;
    }
}
/*485*/
@media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1140px;
    }
}
/*488*/
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.row>*{
    width: auto;
}
/*494*/
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
/*498*/
.no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
/*507*/
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

.row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }

.row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }

.row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }

.row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }

.row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.col-3 {
    flex: 0 0 25%;
    max-width: 25%; }

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }

.col-6 {
    flex: 0 0 50%;
    max-width: 50%; }

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }

.col-9 {
    flex: 0 0 75%;
    max-width: 75%; }

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }

.col-12 {
    flex: 0 0 100%;
    max-width: 100%; }

.order-first {
    order: -1; }

.order-last {
    order: 13; }

.order-0 {
    order: 0; }

.order-1 {
    order: 1; }

.order-2 {
    order: 2; }

.order-3 {
    order: 3; }

.order-4 {
    order: 4; }

.order-5 {
    order: 5; }

.order-6 {
    order: 6; }

.order-7 {
    order: 7; }

.order-8 {
    order: 8; }

.order-9 {
    order: 9; }

.order-10 {
    order: 10; }

.order-11 {
    order: 11; }

.order-12 {
    order: 12; }

.offset-1 {
    margin-left: 8.33333%; }

.offset-2 {
    margin-left: 16.66667%; }

.offset-3 {
    margin-left: 25%; }

.offset-4 {
    margin-left: 33.33333%; }

.offset-5 {
    margin-left: 41.66667%; }

.offset-6 {
    margin-left: 50%; }

.offset-7 {
    margin-left: 58.33333%; }

.offset-8 {
    margin-left: 66.66667%; }

.offset-9 {
    margin-left: 75%; }

.offset-10 {
    margin-left: 83.33333%; }

.offset-11 {
    margin-left: 91.66667%; }

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
    .row-cols-sm-1 > * {
        flex: 0 0 100%;
        max-width: 100%; }
    .row-cols-sm-2 > * {
        flex: 0 0 50%;
        max-width: 50%; }
    .row-cols-sm-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .row-cols-sm-4 > * {
        flex: 0 0 25%;
        max-width: 25%; }
    .row-cols-sm-5 > * {
        flex: 0 0 20%;
        max-width: 20%; }
    .row-cols-sm-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%; }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%; }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%; }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%; }
    .order-sm-first {
        order: -1; }
    .order-sm-last {
        order: 13; }
    .order-sm-0 {
        order: 0; }
    .order-sm-1 {
        order: 1; }
    .order-sm-2 {
        order: 2; }
    .order-sm-3 {
        order: 3; }
    .order-sm-4 {
        order: 4; }
    .order-sm-5 {
        order: 5; }
    .order-sm-6 {
        order: 6; }
    .order-sm-7 {
        order: 7; }
    .order-sm-8 {
        order: 8; }
    .order-sm-9 {
        order: 9; }
    .order-sm-10 {
        order: 10; }
    .order-sm-11 {
        order: 11; }
    .order-sm-12 {
        order: 12; }
    .offset-sm-0 {
        margin-left: 0; }
    .offset-sm-1 {
        margin-left: 8.33333%; }
    .offset-sm-2 {
        margin-left: 16.66667%; }
    .offset-sm-3 {
        margin-left: 25%; }
    .offset-sm-4 {
        margin-left: 33.33333%; }
    .offset-sm-5 {
        margin-left: 41.66667%; }
    .offset-sm-6 {
        margin-left: 50%; }
    .offset-sm-7 {
        margin-left: 58.33333%; }
    .offset-sm-8 {
        margin-left: 66.66667%; }
    .offset-sm-9 {
        margin-left: 75%; }
    .offset-sm-10 {
        margin-left: 83.33333%; }
    .offset-sm-11 {
        margin-left: 91.66667%; } }

/*833*/
@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
    .row-cols-md-1 > * {
        flex: 0 0 100%;
        max-width: 100%; }
    .row-cols-md-2 > * {
        flex: 0 0 50%;
        max-width: 50%; }
    .row-cols-md-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .row-cols-md-4 > * {
        flex: 0 0 25%;
        max-width: 25%; }
    .row-cols-md-5 > * {
        flex: 0 0 20%;
        max-width: 20%; }
    .row-cols-md-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%; }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%; }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%; }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%; }
    .order-md-first {
        order: -1; }
    .order-md-last {
        order: 13; }
    .order-md-0 {
        order: 0; }
    .order-md-1 {
        order: 1; }
    .order-md-2 {
        order: 2; }
    .order-md-3 {
        order: 3; }
    .order-md-4 {
        order: 4; }
    .order-md-5 {
        order: 5; }
    .order-md-6 {
        order: 6; }
    .order-md-7 {
        order: 7; }
    .order-md-8 {
        order: 8; }
    .order-md-9 {
        order: 9; }
    .order-md-10 {
        order: 10; }
    .order-md-11 {
        order: 11; }
    .order-md-12 {
        order: 12; }
    .offset-md-0 {
        margin-left: 0; }
    .offset-md-1 {
        margin-left: 8.33333%; }
    .offset-md-2 {
        margin-left: 16.66667%; }
    .offset-md-3 {
        margin-left: 25%; }
    .offset-md-4 {
        margin-left: 33.33333%; }
    .offset-md-5 {
        margin-left: 41.66667%; }
    .offset-md-6 {
        margin-left: 50%; }
    .offset-md-7 {
        margin-left: 58.33333%; }
    .offset-md-8 {
        margin-left: 66.66667%; }
    .offset-md-9 {
        margin-left: 75%; }
    .offset-md-10 {
        margin-left: 83.33333%; }
    .offset-md-11 {
        margin-left: 91.66667%; } }

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
    .row-cols-lg-1 > * {
        flex: 0 0 100%;
        max-width: 100%; }
    .row-cols-lg-2 > * {
        flex: 0 0 50%;
        max-width: 50%; }
    .row-cols-lg-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .row-cols-lg-4 > * {
        flex: 0 0 25%;
        max-width: 25%; }
    .row-cols-lg-5 > * {
        flex: 0 0 20%;
        max-width: 20%; }
    .row-cols-lg-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%; }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%; }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%; }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%; }
    .order-lg-first {
        order: -1; }
    .order-lg-last {
        order: 13; }
    .order-lg-0 {
        order: 0; }
    .order-lg-1 {
        order: 1; }
    .order-lg-2 {
        order: 2; }
    .order-lg-3 {
        order: 3; }
    .order-lg-4 {
        order: 4; }
    .order-lg-5 {
        order: 5; }
    .order-lg-6 {
        order: 6; }
    .order-lg-7 {
        order: 7; }
    .order-lg-8 {
        order: 8; }
    .order-lg-9 {
        order: 9; }
    .order-lg-10 {
        order: 10; }
    .order-lg-11 {
        order: 11; }
    .order-lg-12 {
        order: 12; }
    .offset-lg-0 {
        margin-left: 0; }
    .offset-lg-1 {
        margin-left: 8.33333%; }
    .offset-lg-2 {
        margin-left: 16.66667%; }
    .offset-lg-3 {
        margin-left: 25%; }
    .offset-lg-4 {
        margin-left: 33.33333%; }
    .offset-lg-5 {
        margin-left: 41.66667%; }
    .offset-lg-6 {
        margin-left: 50%; }
    .offset-lg-7 {
        margin-left: 58.33333%; }
    .offset-lg-8 {
        margin-left: 66.66667%; }
    .offset-lg-9 {
        margin-left: 75%; }
    .offset-lg-10 {
        margin-left: 83.33333%; }
    .offset-lg-11 {
        margin-left: 91.66667%; } }

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }
    .row-cols-xl-1 > * {
        flex: 0 0 100%;
        max-width: 100%; }
    .row-cols-xl-2 > * {
        flex: 0 0 50%;
        max-width: 50%; }
    .row-cols-xl-3 > * {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .row-cols-xl-4 > * {
        flex: 0 0 25%;
        max-width: 25%; }
    .row-cols-xl-5 > * {
        flex: 0 0 20%;
        max-width: 20%; }
    .row-cols-xl-6 > * {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%; }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%; }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%; }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%; }
    .order-xl-first {
        order: -1; }
    .order-xl-last {
        order: 13; }
    .order-xl-0 {
        order: 0; }
    .order-xl-1 {
        order: 1; }
    .order-xl-2 {
        order: 2; }
    .order-xl-3 {
        order: 3; }
    .order-xl-4 {
        order: 4; }
    .order-xl-5 {
        order: 5; }
    .order-xl-6 {
        order: 6; }
    .order-xl-7 {
        order: 7; }
    .order-xl-8 {
        order: 8; }
    .order-xl-9 {
        order: 9; }
    .order-xl-10 {
        order: 10; }
    .order-xl-11 {
        order: 11; }
    .order-xl-12 {
        order: 12; }
    .offset-xl-0 {
        margin-left: 0; }
    .offset-xl-1 {
        margin-left: 8.33333%; }
    .offset-xl-2 {
        margin-left: 16.66667%; }
    .offset-xl-3 {
        margin-left: 25%; }
    .offset-xl-4 {
        margin-left: 33.33333%; }
    .offset-xl-5 {
        margin-left: 41.66667%; }
    .offset-xl-6 {
        margin-left: 50%; }
    .offset-xl-7 {
        margin-left: 58.33333%; }
    .offset-xl-8 {
        margin-left: 66.66667%; }
    .offset-xl-9 {
        margin-left: 75%; }
    .offset-xl-10 {
        margin-left: 83.33333%; }
    .offset-xl-11 {
        margin-left: 91.66667%; } }


/*1750*/
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none; } }
.btn:hover {
    color: #212529;
    text-decoration: none; }
.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
.btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none; }




/*2115*/
.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}
/*2202*/
.collapse:not(.show) {
    display: none;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none; } }
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
/*2983*/
.nav-link {
    display: block;
    padding: 0.5rem 1rem; }
.nav-link:hover, .nav-link:focus {
    text-decoration: none; }
.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }
.nav-tabs {
    border-bottom: 1px solid #dee2e6; }
.nav-tabs .nav-item {
    margin-bottom: -1px; }
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6; }
.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent; }
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/*3040*/
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
/*3048*/
.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
/*3054*/
.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }
/*3065*/
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
/*3071*/
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none; }
/*3083*/
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
/*3088*/
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
/*3098*/
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}
@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row; }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
        display: none; } }

@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
        flex-direction: row; }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
        flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
        display: none; } }

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0; } }
/*3166*/
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row; }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
        display: none; } }

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row; }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
        display: none; } }

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start; }
.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
.navbar-expand .navbar-nav {
    flex-direction: row; }
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
.navbar-expand .navbar-toggler {
    display: none; }

/*3234*/
.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}
/*3239*/
.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}
/*3252*/
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}
/*3256*/
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}




/*4724*/
.bg-light {
    background-color: #f8f9fa !important;
}
/*4863*/
.d-flex {
    display: flex !important;
}


/*5060*/
.align-items-center {
    align-items: center !important;
}
/*5637*/
.mt-4, .my-4 {
    margin-top: 1.5rem !important;
}
/*5042*/
.justify-content-end {
    justify-content: flex-end !important;
}
/*5045*/
.justify-content-center {
    justify-content: center !important;
}
/*5622*/
.mr-3, .mx-3 {
    margin-right: 1rem !important;
}
/*5645*/
.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}
/*5679*/
.pr-0, .px-0 {
    padding-right: 0 !important;
}
/*5687*/
.pl-0, .px-0 {
    padding-left: 0 !important;
}



/*6938*/
.text-center {
    text-align: center !important;
}



/*7165*/
.uniform, body {
    font-family: "uniform" !important;
}

/*7174*/
nav.navbar {
    background: #fff !important;
}
/*7176*/
nav.navbar .navbar-brand img {
    max-width: 150px;
}
/*7178*/
nav.navbar .navbar-brand sup {
    color: #63438e;
    font-weight: bold;
    margin-left: 3px;
}
/*7183*/
footer {
    padding: 140px 0;
    color: #fff;
    background: #2e3f4f;
}
/*7187*/
footer .footer_pushup {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto 140px;
}
/*7191*/
footer .footer_pushup p {
    font-size: 20px;
    line-height: 1.3;
}
/*7194*/
footer .footer_groups {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 0;
}
/*7198*/
footer .footer_groups .footer_group p {
    font-weight: 600;
    font-size: 17px;
}
/*7201*/
footer .footer_groups .footer_group ul {
    padding-left: 0;
    margin-bottom: 0;
}
/*7204*/
footer .footer_groups .footer_group ul li {
    list-style-type: none;
    font-size: 16px;
}
/*7207*/
footer .footer_groups .footer_group ul li a {
    text-decoration: none;
    color: #d6d6d6;
}
/*7210*/
footer .footer_groups .footer_group_copyright img {
    max-width: 200px;
    width: 100%;
}

/*7213*/
footer .footer_groups .footer_group_copyright > div {
    margin-top: 5px;
    color: #b9b9b9;
    font-size: 13px;
}
/*7217*/
footer .footer_contacts {
    padding: 15px 0 0;
}
/*7219*/
footer .footer_contacts .footer_contact a {
    text-decoration: none;
    color: #fff;
}
/*7222*/
footer .footer_soc {
    text-align: right;
}
/*7224*/
footer .footer_soc a {
    text-decoration: none;
    color: #fff;
    font-size: 25px;
    padding: 0 10px;
}
/*7242*/
.EventBlock {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
}
/*7246*/
.EventBlock a {
    text-decoration: none;
}
/*7248*/
.EventBlock_wrapper {
    position: relative;
}
/*7250*/
.EventBlock_category {
    position: absolute;
    background: #63438e;
    color: #fff;
    padding: 1px 8px;
    right: 10px;
    top: 10px;
    font-size: 15px;
    border-radius: 3px;
}
/*7259*/
.EventBlock_header_image {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    height: 150px;
}
.EventBlock_body .EventBlock_name {
    padding: 15px;
    border-top: 1px solid #eee; }
/*7267*/
.EventBlock_body .EventBlock_name h2 {
    color: #333;
    font-size: 18px;
    margin-bottom: 0;
}
/*7271*/
.EventBlock_buy {
    position: relative;
    width: 100%;
    background: #63438e;
    background: -moz-linear-gradient(-45deg, #ba406f 0%, #63438e 100%);
    background: -webkit-linear-gradient(-45deg, #ba406f 0%, #63438e 100%);
    background: linear-gradient(135deg, #ba406f 0%, #63438e 100%);
    color: #fff;
    padding: 10px 0;
    text-align: center;
    transition: .4s all ease;
}
/*7282*/
.EventBlock_buy span {
    position: relative;
    z-index: 5;
}
/*7285*/
.EventBlock_buy::after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: .4s all ease;
}
.EventBlock_buy:hover::after {
    background: rgba(255, 255, 255, 0.15); }

.Contact {
    margin: 30px 0;
    padding: 15px; }
.Contact h2 {
    margin-bottom: 30px;
    text-align: center; }
.ContactItem {
    text-align: center; }
.ContactItem img {
    max-width: 90px; }
.ContactItem_name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 10px; }
.ContactItem > div a {
    color: #ba406f; }

.TicketDetail {
    height: calc(100vh - 55px); }
.TicketDetail.noexist {
    height: auto; }
.TicketDetail.noexist a {
    color: #ba406f; }
.TicketDetail header {
    background: #63438e;
    background: -moz-linear-gradient(-45deg, #ba406f 0%, #63438e 100%);
    background: -webkit-linear-gradient(-45deg, #ba406f 0%, #63438e 100%);
    background: linear-gradient(135deg, #ba406f 0%, #63438e 100%);
    color: #fff;
    padding: 60px;
    text-align: center; }
.TicketDetail iframe {
    width: 100%;
    height: 100%;
    min-height: 780px; }

html, body, #app {
    height: 100%; }

/*7334*/
html, body, #app {
    height: 100%;
}
/*7337*/
body {
    position: relative;
    background: #f5f5f5;
}
/*7341*/
main.main-content {
    min-height: calc(100% - 800px);
}
/*7344*/
.shadowed {
    box-shadow: 0 5px 20px rgba(141, 141, 141, 0.1);
}
/*7347*/
.eventlist {
    padding: 30px 0;
}
/*7350*/
.categorieslist {
    margin-top: 30px;
    position: relative;
}
/*7355*/
.categorieslist::after {
    /* content: ''; */
    position: absolute;
    width: 80%;
    background: #d4d4d4;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
}
/*7361*/
.categorieslist .category-item {
    cursor: pointer;
    display: inline-block;
    box-sizing: content-box;
    -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
    position: relative;
    height: 7px;
    padding: 16px 16px;
    font-size: 16px;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    background-image: radial-gradient(circle at 0 50%, rgba(255, 255, 224, 0) 0.4em, #fff 0.5em), radial-gradient(circle at 100% 50%, rgba(255, 255, 224, 0) 0.4em, #fff 0.5em);
    background-position: top left, top right;
    margin: 0 20px;
    line-height: .5;
    color: #333;
    z-index: 2;
}
@media (max-width: 991.98px) {
    .categorieslist .category-item {
        margin: 0 15px 10px; } }
/*7383*/
.categorieslist .category-item.selected {
    background-image: radial-gradient(circle at 0 50%, rgba(255, 255, 224, 0) 0.4em, #ba406f 0.5em), radial-gradient(circle at 100% 50%, rgba(255, 255, 224, 0) 0.4em, #ba406f 0.5em);
    color: #fff;
}
/*7388*/
::-webkit-scrollbar {
    width: 7px;
}
/*7391*/
::-webkit-scrollbar-track {
    background: #c5c5c5;
}
/*7394*/
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555; }
/*7400*/
section#douwant {
    background: #fff;
    padding: 60px 0;
}
/*7403*/
section#douwant h2 {
    margin-bottom: 0;
}
/*7406*/
section#products {
    padding: 0;
    background: #f5f5f5;
}
/*7409*/
section#products a {
    text-decoration: none;
}
/*7411*/
section#products .enigoo-product-item {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 200px 0;
    transition: .4s all ease;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
/*7421*/
section#products .enigoo-product-item::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #63438e;
    opacity: .6;
    transition: .4s all ease;
}
section#products .enigoo-product-item:hover::after {
    opacity: .9; }
/*7433*/
section#products .enigoo-product-item.enigoo {
    background: url("https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80");
}
/*7435*/
section#products .enigoo-product-item.enigoobox {
    background: url("https://images.unsplash.com/photo-1556740772-1a741367b93e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
}
/*7437*/
section#products .enigoo-product-item.safegoo {
    background: url("https://images.pexels.com/photos/4429561/pexels-photo-4429561.jpeg?auto=compress&cs=tinysrgb&h=650&w=940");
}
/*7439*/
section#products .enigoo-product-item_content {
    position: relative;
    z-index: 3;
}
/*7442*/
section#products .enigoo-product-item p {
    font-size: 18px;
    margin-bottom: 0;
    padding-left: 19px;
}
/*7445*/
section#products .enigoo-product-item img {
    height: 25px;
    margin-bottom: 15px;
}

#btn-retry{
    color: #f8f9fa;
    background-color: #2e3f4f;
    border: 0.375rem solid transparent;
    padding-top: 0;
    padding-bottom: 0;
}


@font-face {
    font-family: 'uniform';
    src: url("../fonts/Uniform.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Uniform.eot") format("embedded-opentype"), url("../fonts/Uniform.woff2") format("woff2"), url("../fonts/Uniform.woff") format("woff"), url("../fonts/Uniform.ttf") format("truetype");
    /* Pretty Modern Browsers */ }

@font-face {
    font-family: 'uniform-light';
    src: url("../fonts/Uniform-Light.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Uniform-Light.eot") format("embedded-opentype"), url("../fonts/Uniform-Light.woff2") format("woff2"), url("../fonts/Uniform-Light.woff") format("woff"), url("../fonts/Uniform-Light.ttf") format("truetype");
    /* Pretty Modern Browsers */ }

@font-face {
    font-family: 'uniform-bold';
    src: url("../fonts/Uniform-Bold.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Uniform-Bold.eot") format("embedded-opentype"), url("../fonts/Uniform-Bold.woff2") format("woff2"), url("../fonts/Uniform-Bold.woff") format("woff"), url("../fonts/Uniform-Bold.ttf") format("truetype");
    /* Pretty Modern Browsers */ }











